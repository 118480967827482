import React from "react"
import { graphql } from "gatsby"
import Layout from "components/Layout"
import SEO from "components/SEO"

const NotFoundPage = ({ data: { site, prismic }, ...props }) => {
  const { siteUrl, ...defaultMeta } = site.defaultMeta
  const uri = "/404/"

  const {
    site_settings: {
      default_meta_title: meta_title,
      default_meta_description: meta_description,
      default_meta_image: meta_images,
    },
  } = prismic

  return (
    <>
      <SEO
        lang="da-dk"
        slug={uri}
        title={meta_title}
        description={meta_description}
        images={meta_images}
        defaultMeta={defaultMeta}
      />
      <Layout site_settings={prismic.site_settings}>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist.</p>
      </Layout>
    </>
  )
}

export default NotFoundPage

export const query = graphql`
  query Page404($isProduction: Boolean!) {
    site {
      defaultMeta: siteMetadata {
        ...SiteMetaFragment
      }
    }
    prismic {
      site_settings(lang: "da-dk", uid: "site-settings") {
        copyright_information
        ...PrismicSiteSettingsMetaFragment
        ...SiteNavigation
        ...FooterNavigation
        ...SocialMediaLinks
        _meta @include(if: $isProduction) {
          uid
        }
      }
    }
  }
`
